import { Center, Icon, Link, LinkProps, useColorModeValue, Tooltip } from '@chakra-ui/react'
import * as React from 'react'

interface NavLinkProps extends LinkProps {
  isActive?: boolean
  href?: string
  label: string
  icon: any
  isExternal?: boolean
}

function NavLink(props: NavLinkProps) {
  const bg = useColorModeValue('red.700', 'blue.700')

  const { icon, isActive, label, href, isExternal, ...rest } = props
  return (
    <Tooltip label={label} placement="auto">
      <Link
        href={href}
        isExternal={isExternal}
        display="block"
        py={2}
        px={3}
        borderRadius="md"
        transition="all 0.3s"
        fontWeight="medium"
        lineHeight="1.5rem"
        aria-current={isActive ? 'page' : undefined}
        color="whiteAlpha.900"
        _hover={{
          bg,
          color: 'white',
        }}
        _activeLink={{
          bg,
          color: 'white',
        }}
        {...rest}
      >
        <Center>
          <Icon as={icon} boxSize="20px" />
        </Center>
      </Link>
    </Tooltip>
  )
}

export default NavLink

NavLink.defaultProps = {
  isActive: undefined,
  href: undefined,
  isExternal: undefined,
}
