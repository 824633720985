import { Flex, Grid, Heading, Img, Link, Stack, Text } from "@chakra-ui/react";
import * as React from "react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { getAge } from "../utils";
import ProfileImage from "../assets/profil.jpeg";
import FadeInUpBox from "../components/fade-in-up-box";

function About() {
  return (
    <Grid minH="100vh" p={3}>
      <Stack
        justifyContent="space-around"
        alignSelf="center"
        direction={['column', 'column', 'column', 'row']}
        p={[4, 6, 10, 15, 40]}
      >
        <Flex basis="auto" shrink={0} justifyContent="center" alignItems="center">
          <FadeInUpBox yOffset={64} duration={1}>
            <Img
              borderRadius="full"
              boxSize={['100px', '200px', '300px', '300px']}
              src={ProfileImage}
              alt="Julien Le Pêcheur"
              className="object-cover w-full"
            />
          </FadeInUpBox>
        </Flex>
        <Flex className="max-w-screen-md" direction="column" justifyContent="center" p={[0, 0, 0, 10]}>
          <FadeInUpBox yOffset={64} duration={1}>
            <Heading className="mb-4" as="h2" size="3xl">
              About
            </Heading>
          </FadeInUpBox>
          <FadeInUpBox yOffset={64} duration={1} delayOrder={2}>
            <Text fontSize="lg" className="text-justify">
              Hello, Julien, {getAge('1998-10-19')} years old, with several years of experience in web development,
              especially with the latest technologies. I mainly acquired these skills thanks to my development projects
              done on my personal time. I am currently working at Jellysmack, as a backend developer, where I am in charge
              of developing a web platform using technologies such as Python, Nodejs, FastAPI, React, Angular, Redis, Docker, AWS...
            </Text>
            <br />
            <Text fontSize="lg" className="text-justify">
              I am currently looking for full time freelance jobs full remote. — hire me on{' '}
              <Link color="teal.500" isExternal href="https://www.malt.fr/profile/julienlepecheur">
                Malt <ExternalLinkIcon mx="2px" />
              </Link>
            </Text>
          </FadeInUpBox>
        </Flex>
      </Stack>
    </Grid>
  )
}

export default About
