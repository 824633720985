import { Flex, Grid, Heading, Stack } from '@chakra-ui/react'
import * as React from 'react'
import FadeInUpBox from '../components/fade-in-up-box'
import ExperienceCard, { ExperienceCardProps } from '../components/experience-card'

function Experiences() {
  const experiences: ExperienceCardProps[] = [
    {
      company: 'Jellysmack',
      companyUrl: 'https://jellysmack.com/',
      jobName: 'Backend developer',
      jobDescription: 'Development of a products to find the most promising video creators on Internet',
      startDate: new Date('2022-05-30'),
      skills: ['Python', 'FastAPI', 'Git', 'Scrum'],
    },
    {
      company: 'FIME',
      companyUrl: 'https://www.fime.com/',
      jobName: 'Fullstack developer',
      jobDescription: 'Development of a test tool for smart cards and payment terminals.',
      startDate: new Date('2019-09-01'),
      endDate: new Date('2022-05-30'),
      skills: ['Angular', 'Python', 'Django', 'Electron', 'Git', 'Scrum'],
    },
    {
      company: 'DIDACTIC',
      companyUrl: 'https://didactic.care/',
      jobName: 'Trainee developer',
      jobDescription: 'PHP/SQL development of the intranet',
      startDate: new Date('2018-04-01'),
      endDate: new Date('2018-06-31'),
      skills: ['PHP', 'SQL', 'Git'],
    }
  ]

  return (
    <Grid minH="100vh" p={3}>
      <Stack
        justifyContent="space-around"
        alignSelf="center"
        direction={['column', 'column', 'column', 'row']}
        p={[4, 6, 10, 15, 40]}
      >
        <Flex className="max-w-screen-md" direction="column" justifyContent="center" p={[0, 0, 0, 10]}>
          <FadeInUpBox yOffset={64} duration={1}>
            <Heading className="mb-4" as="h2" size="3xl">
              Experiences
            </Heading>
          </FadeInUpBox>

          <Flex gap={2} wrap="wrap" >
            {experiences.map((exp, index) => (
              <Flex basis={['100%', "100%", "100%", '45%']}>
                <FadeInUpBox yOffset={64} duration={0.5} delayOrder={2 + index * 0.5}>
                  <ExperienceCard
                    key={exp.company}
                    company={exp.company}
                    companyUrl={exp.companyUrl}
                    jobName={exp.jobName}
                    jobDescription={exp.jobDescription}
                    startDate={exp.startDate}
                    endDate={exp.endDate}
                    skills={exp.skills}
                  />
                </FadeInUpBox>
              </Flex>
            ))}
          </Flex>
        </Flex>
      </Stack>
    </Grid>
  )
}

export default Experiences
