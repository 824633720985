import { Divider, Flex, Stack, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'
import { FaGithub, FaHome, FaLinkedin } from 'react-icons/fa'
import NavLink from './NavLink'
import MaltIcon from '../malt-icon'

function Sidebar() {
  const bg = useColorModeValue('red.500', '#181818')
  const color = useColorModeValue('white', 'gray.800')

  return (
    <Flex height="100vh" width="100px" direction="column" justifyContent="center" bg={bg} color={color} px={6} py={8}>
      <Stack spacing={6}>
        <Stack>
          <NavLink label="Home" icon={FaHome} isActive />
        </Stack>
        <Divider borderColor="whiteAlpha.400" />
        <Stack>
          <NavLink href="https://github.com/WarKaa" isExternal label="Github" icon={FaGithub} />
          <NavLink
            href="https://www.linkedin.com/in/julien-le-pecheur/"
            isExternal
            label="LinkedIn"
            icon={FaLinkedin}
          />
          <NavLink href="https://www.malt.fr/profile/julienlepecheur" isExternal label="Malt" icon={MaltIcon} />
        </Stack>
      </Stack>
    </Flex>
  )
}
export default Sidebar
