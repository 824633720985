import React, { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { motion } from 'framer-motion'

export interface FadeInUpBoxInterface {
  children: any
  yOffset?: number
  easing?: number[]
  delayOrder?: number
  // All other props
  [x: string]: any
}

function FadeInUpBox({
  children,
  yOffset = 24, // y initial possition
  easing = [0.42, 0, 0.58, 1], // [number, number, number, number] | "linear" | "easeIn" |
  //  "easeOut" | "easeInOut" | "circIn" | "circOut" | "circInOut" | "backIn" | "backOut" |
  // "backInOut" | "anticipate" | EasingFunction;
  delayOrder, // order of appearance
  ...rest
}: FadeInUpBoxInterface) {
  const [titleRef, inView] = useInView({
    triggerOnce: true,
  })
  const [delay, setDelay] = useState(0.25)

  const offset = 0.4

  useEffect(() => {
    if (delayOrder) setDelay(delayOrder * offset)
  }, [delayOrder, offset])

  const transition = useMemo(
    () => ({
      duration: 0.4,
      delay,
      ease: easing,
    }),
    [delay, easing],
  )

  const variants = {
    hidden: { y: yOffset, opacity: 0, transition },
    show: {
      y: 0,
      x: 0,
      opacity: 1,
      transition,
    },
  }

  return (
    <motion.div
      ref={titleRef}
      initial="hidden"
      animate={inView ? 'show' : 'hidden'}
      exit="hidden"
      variants={variants}
      {...rest}
    >
      {children} {inView}
    </motion.div>
  )
}

export default FadeInUpBox

FadeInUpBox.defaultProps = {
  yOffset: 24, // y initial possition
  easing: [0.42, 0, 0.58, 1],
  delayOrder: undefined,
}
