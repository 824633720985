import * as React from 'react'
import '@fontsource/league-spartan/700.css'
import '@fontsource/league-spartan'
import './index.css'
import { ChakraProvider } from '@chakra-ui/react'
import { Route, Routes } from 'react-router-dom'
import Home from './pages/home'
import theme from './theme'

export default function App() {
  return (
    <ChakraProvider theme={theme}>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
    </ChakraProvider>
  )
}
