import { Icon } from '@chakra-ui/react'
import React from 'react'

function MaltIcon(props: any) {
  return (
    <Icon viewBox="0 0 279.000000 276.000000" {...props}>
      <g transform="translate(0.000000,276.000000) scale(0.100000,-0.100000)" fill="#fff" stroke="none">
        <path
          d="M1353 2750 c-54 -11 -108 -40 -160 -85 -49 -42 -109 -157 -118 -225
l-6 -45 167 -173 c92 -95 172 -172 178 -170 6 2 85 78 175 170 l164 167 -6 59
c-7 79 -50 173 -100 218 -77 70 -197 104 -294 84z"
        />
        <path
          d="M551 2461 c-58 -22 -68 -29 -124 -80 -77 -72 -117 -193 -97 -298 12
-69 39 -122 92 -185 l40 -48 372 0 372 0 54 55 53 54 -222 230 c-256 265 -280
282 -410 288 -58 3 -89 -1 -130 -16z"
        />
        <path
          d="M2018 2447 c-20 -8 -55 -25 -76 -38 -22 -13 -384 -365 -804 -781
-542 -537 -772 -771 -790 -805 -93 -171 -25 -382 152 -474 39 -21 59 -24 145
-24 85 0 108 4 150 24 50 24 262 231 1291 1256 372 371 369 366 369 515 0 81
-4 103 -25 145 -31 64 -102 134 -165 164 -62 30 -190 39 -247 18z"
        />
        <path
          d="M295 1729 c-140 -19 -244 -115 -281 -259 -14 -55 -15 -77 -5 -138 25
-164 117 -248 301 -274 l81 -12 342 342 342 342 -330 1 c-182 1 -346 2 -365 3
-19 1 -57 -1 -85 -5z"
        />
        <path
          d="M2320 1669 c-36 -34 -191 -186 -345 -339 -249 -248 -278 -279 -255
-282 14 -2 183 -5 375 -6 393 -3 430 1 521 63 54 37 78 65 115 135 21 39 24
59 24 145 0 89 -3 106 -27 150 -32 62 -103 129 -163 155 -39 17 -141 41 -169
40 -6 0 -40 -28 -76 -61z"
        />
        <path
          d="M1510 875 l-54 -54 49 -53 c243 -263 383 -400 440 -430 56 -31 67
-33 155 -33 84 0 102 3 151 28 66 32 132 99 162 165 18 38 22 66 22 142 0 111
-18 158 -92 240 l-45 50 -367 0 -367 0 -54 -55z"
        />
        <path
          d="M1206 560 l-169 -169 7 -56 c16 -138 98 -248 217 -290 70 -25 179
-21 244 9 111 50 202 173 213 287 l5 51 -169 169 c-93 93 -171 169 -174 169
-3 0 -82 -76 -174 -170z"
        />
      </g>
    </Icon>
  )
}

export default MaltIcon
