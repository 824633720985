import { Box, Heading, Text, Badge, Flex, Link } from '@chakra-ui/react'
import * as React from 'react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import dayjs from 'dayjs'

export interface ExperienceCardProps {
  startDate: Date
  endDate?: Date | undefined
  company: string
  companyUrl?: string
  jobName: string
  jobDescription: string
  skills?: string[]
}

function ExperienceCard(props: ExperienceCardProps) {
  const { startDate, endDate, company, companyUrl, jobName, jobDescription, skills } = props

  return (
    <Flex direction="column" as="article" maxW="xs" p="5" borderWidth="1px" rounded="md" h="100%">
      <Box as="time">
        {endDate ? (
          <>
            {dayjs(startDate).format('MMMM YYYY')} - {dayjs(endDate).format('MMMM YYYY')}
          </>
        ) : (
          <>Since {dayjs(startDate).format('MMMM YYYY')}</>
        )}
      </Box>
      <Heading size="md" my="2">
        {companyUrl ? (
          <Link color="teal.500" href={companyUrl} isExternal>
            {company} <ExternalLinkIcon mx="2px" />
          </Link>
        ) : (
          { company }
        )}{' '}
        — {jobName}
      </Heading>
      <Text flex={1} mb={2}>
        {jobDescription}
      </Text>
      <Flex wrap="wrap" w="100%" gap={2}>
        {skills?.map((s) => (
          <Badge alignItems="center" key={s}>
            {s}
          </Badge>
        ))}
      </Flex>
    </Flex>
  )
}

ExperienceCard.defaultProps = {
  endDate: undefined,
  companyUrl: undefined,
  skills: [],
}

export default ExperienceCard
