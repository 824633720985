import * as React from 'react'
import { Flex, Show } from '@chakra-ui/react'
import { FaGithub, FaLinkedin } from 'react-icons/fa'
import Sidebar from '../components/sidebar/Sidebar'
import Hello from './hello'
import About from './about'
import NavLink from '../components/sidebar/NavLink'
import MaltIcon from '../components/malt-icon'
import Experiences from './experiences'

export default function Home() {
  return (
    <Flex>
      <Show above="md">
        <Sidebar />
      </Show>
      <Flex style={{ maxHeight: '100vh', overflowY: 'scroll' }} direction="column" basis="100%">
        <Hello />
        <About />
        <Experiences/>
        <Show below="md">
          <footer>
            <Flex justifyContent="center" p={5}>
              <NavLink href="https://github.com/WarKaa" isExternal label="Github" icon={FaGithub} />
              <NavLink
                href="https://www.linkedin.com/in/julien-le-pecheur/"
                isExternal
                label="LinkedIn"
                icon={FaLinkedin}
              />
              <NavLink href="https://www.malt.fr/profile/julienlepecheur" isExternal label="Malt" icon={MaltIcon} />
            </Flex>
          </footer>
        </Show>
      </Flex>
    </Flex>
  )
}
