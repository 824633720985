import { Box, Flex, Grid, Heading, Stack, useBreakpointValue } from '@chakra-ui/react'
import Typed from 'react-typed'
import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import HALO from 'vanta/dist/vanta.halo.min'
import FadeInUpBox from '../components/fade-in-up-box'

function Hello() {
  const xOffset = useBreakpointValue({ base: 0.5, sm: 0.2, xl: 0 })

  const [vantaEffect, setVantaEffect] = useState<any>(null)

  const ref = useRef(null)

  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        HALO({
          el: ref.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          amplitudeFactor: 0.0,
          yOffset: -0.01,
          xOffset,
          size: 0.6,
          backgroundColor: '#1A202C',
          baseColor: 'black',
        }),
      )
    } else {
      vantaEffect.setOptions({ xOffset })
    }
  }, [vantaEffect, xOffset])

  useEffect(() => () => {
      if (vantaEffect) vantaEffect.destroy()
    }, [vantaEffect])

  return (
    <Box flex="1" textAlign="left" fontSize="xl" ref={ref}>
      <Grid minH="100vh" p={3}>
        <Stack alignSelf="center" direction="row" h="50vh" p={4}>
          <Flex direction="column" justifyContent="center">
            <Heading className="font-bold select-none" as="h1" size="4xl">
              <FadeInUpBox>Hello,</FadeInUpBox>
              <FadeInUpBox delayOrder={2}>I am Julien,</FadeInUpBox>
              <FadeInUpBox delayOrder={3}>
                <Typed
                  strings={['web developer', 'fullstack developer', 'frontend developer', 'backend developer']}
                  typeSpeed={40}
                  startDelay={1000}
                  loop
                />
              </FadeInUpBox>
            </Heading>
          </Flex>
        </Stack>
      </Grid>
    </Box>
  )
}

export default Hello
